.Homecoming {
    padding-top: 10vh;
}

.Card {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20vh;
    min-height: 300px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px #eee;
    display: block;
    border-radius: 20px;
    /*background: url('../../assets/images/official/hero.jpg');*/
    background-color: #061c2b;
    /*background-size: cover;*/
    color: rgb(238, 238, 238);
    font-size: 120%;
    /*box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.6);*/
    position: relative;
}

.Card div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Overlay {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.Header {
    font-size: 200%;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    padding-bottom: 8vh;
    padding-top: 5vh;
    letter-spacing: .2rem;
}

.Title {
    display: block;
    margin-bottom: 1vh;
    line-height: 2rem;
}

.TitleProp {
    font-weight: 800;
    padding-right: 10px;
}

@media (max-width: 1001px) {

    .TextContainer {
        display: block;
    }
}

@media (max-width: 800px) {
    .Title {
        font-size: 120%;
    }
}