.Container {
    text-align: center;
    padding-top: 50px;
}

.Container textarea {
    width: 90%;
    margin: auto;
    padding-top: 10px;
    margin-bottom: 20px;
}

.Container button {
    width: 90%;
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: #eee;
    color: rgb(10, 16, 77);
    font-size: 150%;
    text-transform: uppercase;
    letter-spacing: .2rem;
    cursor: pointer;
    transition: .7s;
}

.Container button:hover {
    box-shadow: 0 10px 20px #eee;
    color: rgb(6, 97, 67);
}

.dateInput {
    font-size: 150%;
    display: block;
    margin-top: 10px;
    width: 30%;
    margin: auto;
    margin-bottom: 40px;
}

.photoInput {
    font-size: 150%;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 30%;
    margin-left: 35%;
    padding-bottom: 20px;
}

.photoInput button {
    background-color: orange;
    color: white;
    border: none;
    cursor: pointer;
    transition: .6s;
}

.preview img {
    width: 60%;
    height: auto;
    margin: auto;
    padding-bottom: 75px;
}

.userName {
    font-size: 110%;
    padding: 10px;
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
}