.Graves {
    padding-top: 150px;
    padding-bottom: 40px;
    background-color: #061c2b;
    color: #eee;
}

.Graves h1, .Graves h2 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 220%;
    font-weight: 300;
    font-variant: small-caps;
}

.Graves h2 {
    margin-top: 100px;
}

.Contribute {
    margin-top: 30px;
}

.Graves p {
    width: 70%;
    margin: auto;
    line-height: 1.5rem;
    text-indent: 5%;
    font-weight: 300;
    word-spacing: .2rem;;
}

.Graves button {
    width: 20%;
    padding: 25px;
    margin-left: 40%;
    margin-top: 50px;
    background-color: rgb(201, 98, 29);
    color: #eee;
    border: none;
    cursor: pointer;
    font-size: 125%;
    transition: .5s;
}

.Graves button:hover {
    background-color: #eee;
    color: rgb(201, 98, 29);
}

.Graves img {
    width: 80%;
    height: auto;
    margin-left: 10%;
    padding-bottom: 50px;
    padding-top: 40px;
    
}

.Graves ul {
    text-align: center;
    margin-top: 50px;
    line-height: 2.5rem;
}

.PadTop {
    padding-top: 40px;
}

@media (max-width: 1001px) {
    .Graves button {
        width: 50%;
        margin-left: 25%;
    }
}