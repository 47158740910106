.ContactForm {
    background: url(../../assets/images//official/grounds1.jpg);
    background-size: cover;
    width: 100%;
    height: 950px;
}

.Overlay {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
}

.Header {
    text-align: center;
    font-size: 200%;
    letter-spacing: .2rem;
    text-transform: uppercase;
    font-weight: 300;
    padding-top: 15vh;
    color: #fff;
    margin-bottom: 6vh;
}

.UserDetails {
    display: inline-grid;
    margin-right: 20px;
    background-color: #0000009e;
    padding: 10px;
}

.Submit {
    text-align: center;
    border: none;
    background-color: rgb(36, 129, 235);
    color: #fff;
    padding: 8px 15px;
    letter-spacing: .2rem;
    text-transform: uppercase;
    font-size: 120%;
    margin-top: 20px;
    border: 3px solid  rgb(255, 255, 255) transparent;
    transition: .6s;
    cursor: pointer;
}

.Submit:hover {
    border: 3px solid  rgb(255, 255, 255);
}

.UserDetails input {
    display: block;
    padding: 10px;
    border: 1px solid rgb(37, 37, 37);
    box-shadow: 0 2px 3px #000;
    margin-bottom: 10px;
    width: 300px;
    font-size: 120%;
    background-color: rgba(0, 0, 0, 0.616);
    color: rgb(71, 71, 71);
}

.UserDetails button {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    width: 300px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    color: rgb(13, 143, 194);
    font-weight: 500;
    font-size: 120%;
    border: 1px solid #fff;
    transition: .6s;
}

.UserDetails button:hover {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    width: 300px;
    cursor: pointer;
    background-color: rgb(13, 143, 194);
    color: #fff;
}

.Form {
    width: fit-content;
    margin: 0 auto;
    padding: 30px;
}

.Label {
    font-size: 120%;
    color: #ddd;
}

textArea {
    display: block;
    height: 300px;
    width: 97%;
    background: transparent;
    color: #fff;
    font-size: 120%;
    background-color: rgba(0, 0, 0, 0.616);
    margin-top: 2vh;
}

.U input {
    display: block;
    padding: 10px;
    border: 1px solid rgb(37, 37, 37);
    box-shadow: 0 2px 3px #000;
    margin-bottom: 10px;
    width: 620px;
    height: 300px;
    font-size: 120%;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.432);
    color: #fff;
}

.UserDetails label {
    display: inline;
}

.Grid {
    display: flex;
}

.Grid Input {
    margin-top: 8px;
    margin-right: 10px;
    margin-bottom: 34px;
}

@media (max-width: 1001px) {
    .ContactForm {
        background: rgb(39, 39, 39);
        height: 1250px;
    }

    .UserDetails {
        margin-top: 50px;
    }

    .Header {
        margin-bottom: 0;
    }
}