.Footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 8vh;
    margin-bottom: 8vh;
}

.Footer a {
    text-decoration: none;
    font-size: 80%;
    font-weight: 300;
    letter-spacing: .2rem;
    line-height: 2rem;
    text-decoration: none;
    transition: 06.s;
    text-align: center;
    color: #9b2d12;
    padding-bottom: 50px;
}

.Footer a:hover {
    color: #852407;
}

.ULcontainer {
    width: fit-content;
    vertical-align: middle;
    margin: 0 auto;
}

.Alex a{
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    color: #125b9b;
    padding-bottom: 50px;
}

.UL {
    list-style: none;
    font-size: 120%;
}

.RightSide {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}

.RightProp img {
    width: 30px;
    padding-right: 10px;
}

.RightProp span {
    vertical-align: top;
}

.RightProp span a {
    font-size: 80%;
    vertical-align: super;
    font-weight: 600;
}

@media (max-width: 800px) {
    .Footer {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .RightSide {
        margin: auto;
        margin-top: 40px;
        font-weight: 500;
        line-height: 3rem;
    }

    .Alex {
        margin: auto;
    }

    .RightProp span a, .Footer a {
        font-weight: 600;
    }
    
}