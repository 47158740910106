.Photo {
    display: inline-block;
    width: 350px;
    min-height: 500px;
    padding: 2vh;
    margin-right: 5vh;
    background-color: rgb(241, 241, 241);
    color: rgb(29, 29, 29);
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px rgb(9, 52, 77);
}

.PropsContainer {
    text-align: center;
    position: relative;
}

.Photo h4 {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    letter-spacing: .2rem;
}

.Photo p {
    width: 100%;
    margin-bottom: 5vh;
    margin-left: 0;
    margin-right: 0;
    text-align: justify;
    word-spacing: .1rem;
}

.Photo img {
    width: 320px;
    height: auto;

    margin: auto;
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px #eee;
}

.Photo button {
    background-color: #05193f;
    color: #fff;
    padding: 8px 15px;
    border: none;
    width: 160px;
    margin-right: 2vh;
    transition: .5s;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-bottom: 2vh;
}

.Photo .NewPhotos {
    background-color: #d65518;
    transition: .5s;
}

.Photo button:hover {
    background-color: rgb(231, 231, 231);
    color: #05193f;
    font-weight: 500;
}

.ButtonContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 20%);
}

@media (max-width: 1001px) {
    .Photo {
        margin-bottom: 5vh;
        min-height: 570px;
    }

    .Photo h4 {
        font-size: 125%;
        margin: 0 auto;
        margin-top: 10px;
    }
}