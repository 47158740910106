.Mission {
    display: block;
    margin: 5vh 20vh;
}

.Mission p {
    margin-bottom: 2vh;
    text-indent: 3%;
    font-size: 110%;
    line-height: 2rem;
    
}

.Mission h2 {
    text-align: center;
    margin-bottom: 5vh;
    font-size: 200%;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .2rem;
}

.Mission a {
    text-decoration: none;
    color:#e45e20;
   
    transition: .3s;
}

.Mission a:hover {
    color: #2075e4;
}

@media (max-width: 1001px) {
    .Mission {
        margin: 0 auto;
    }

    .Mission h2 {
        margin: 0 auto;
        padding-top: 100px;
    }

    .Mission p {
        margin: 0 auto;
        padding: 30px;
    }
}