* {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
}

*::-webkit-scrollbar {
    width: 1em;
  }
   
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  *::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

@media (max-width: 1001px) {
    * {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    body {
        position: absolute;
    }
}