.PhotoSubmission {
    margin-top: 150px;
}

.PhotoSubmission h1 {
    text-align: center;
    font-weight: 300;
}

.PhotoSubmission p {
    text-indent: 2rem;
    width: 70%;
    margin: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.PhotoSubmission ul {
    width: 70%;
    margin: auto;
    padding-top: 5vh;
    line-height: 2rem;
}

.UL {
    color: rgb(243, 243, 243);
    background-color: #070f3f;
}

@media (max-width: 800px) {
    .PhotoSubmission h1 {
        font-size: 250%;
    }
}