.Hero {
    width: 100%;
    height: 100vh;
    background: url('../../assets/images/official/hero3.jpg') no-repeat center center fixed;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Overlay { 
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.25);
}

.Header {
    font-weight: 300;
    font-size: 340%;
    letter-spacing: .2rem;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-variant: small-caps;
}

.SubHeader {
    display: block;
    font-size: 50%;
    font-weight: 300;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-variant: all-petite-caps;
    font-style: italic;
}

.HeaderContainer {
    color: #fff;
    text-align: center;
    padding-top: 30vh;
    margin-bottom: 20vh;
}

.HeroBtnContainer a {
    text-decoration: none;
}

.HeroBtn {
    display: block;
    width: 200px;
    margin: auto;
    background-color: #d6501a;
    color: #fff;
    text-transform: uppercase;
    padding: 15px 15px;
    margin-bottom: 10px;
    border: 3px solid rgb(240, 240, 240);
    text-align: center;
    cursor: pointer;
    transition: .7s;
    text-decoration: none;
}

.HeroBtn:hover, aHeroBtn a:hover {
    color: #d6501a;
    border: 3px solid #d6501a;
    background-color: #fff;
    
}

.HeroBtn a {
    color: #fff;
    text-decoration: none;
}

.HeroBtn a:hover {
    color: #d6501a;
}

@media (max-width: 1001px) {
    .Header, .SubHeader {
        margin: 0 auto;
    }

    .Header {
        font-size: 140%;
        padding-bottom: 50px;
    }

    .HeaderContainer {
        margin-bottom: 5vh;
    }

    .SubHeader {
        font-size: 100%;
    }
}

@media(max-width: 800px) {
    .Header {
        font-size: 280%;
    }
}